import React from "react"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"
import { Section, MarkdownContent } from "../components/Section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PatternImg from "../images/line-pattern.png"
import RequestForm from "../components/RequestForm"
import "@material/react-select/dist/select.css"
import StickyBox from "react-sticky-box"

class PrivacyPolicy extends React.Component {
  render() {
    const { location } = this.props;
    const formId = "Request a Quote";
    return (
      <Layout location = { location }>
        <SEO title="Privacy Policy" />
        <section style={{ background: `url(${PatternImg})` }}>
          <Container className="position-relative">
            <Breadcrumb className="beadcrumbs-outer">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Privacy Policy</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </section>
        <Section className="section" pt="100px" pb="100px">
          <Container>
            <Row>
              <Col lg={7} xl={8}>
                <MarkdownContent>
                  <span>
                    This Application collects some Personal Data from its Users.
                  </span>
                  <h1>Types of Data Collected</h1>
                  <p>Carports R Us <a href="mailto:carportrus@gmail.com" target="_blank" rel="noopener noreferrer">carportrus@gmail.com</a></p>
                  <p>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies, Usage data, email address, first name, last name, address, password, state, ZIP/Postal code and city.</p>
                  <p>Other Personal Data collected may be described in other sections of this privacy policy or by dedicated explanation text contextually with the Data collection.</p>
                  <p>The Personal Data may be freely provided by the User, or collected automatically when using this Application.</p>
                  <p>Any use of Cookies — or of other tracking tools — by this Application or by the owners of third party services used by this Application, unless stated otherwise, serves to identify Users and remember their preferences, for the sole purpose of providing the service required by the User.</p>
                  <p>Failure to provide certain Personal Data may make it impossible for this Application to provide its services.</p>
                  <p>Users are responsible for any Personal Data of third parties obtained, published or shared through this Application and confirm that they have the third party’s consent to provide the Data to the Owner.</p>

                  <h2>Mode and Place of Processing the Data</h2>
                  <p>
                    Methods of Processing The Data Controller processes the Data
                    of Users in a proper manner and shall take appropriate
                    security measures to prevent unauthorized access,
                    disclosure, modification, or unauthorized destruction of the
                    Data.
                  </p>
                  <p>
                    The Data processing is carried out using computers and/or IT
                    enabled tools, following organizational procedures and modes
                    strictly related to the purposes indicated. In addition to
                    the Data Controller, in some cases, the Data may be
                    accessible to certain types of persons in charge, involved
                    with the operation of the site (administration, sales,
                    marketing, legal, system administration) or external parties
                    (such as third party technical service providers, mail
                    carriers, hosting providers, IT companies, communications
                    agencies) appointed, if necessary, as Data Processors by the
                    Owner. The updated list of these parties may be requested
                    from the Data Controller at any time.
                  </p>
                  <p>
                    Place The Data is processed at the Data Controller’s
                    operating offices and in any other places where the parties
                    involved with the processing are located. For further
                    information, please contact the Data Controller.
                  </p>
                  <p>
                    Retention Time The Data is kept for the time necessary to
                    provide the service requested by the User, or stated by the
                    purposes outlined in this document, and the User can always
                    request that the Data Controller suspend or remove the data.
                  </p>
                  <p>
                    The Use of the Collected Data The Data concerning the User
                    is collected to allow the Owner to provide its services, as
                    well as for the following purposes: Analytics, Interaction
                    with online survey platforms, Remarketing and Behavioral
                    Targeting, Interaction with live chat platforms, Heat
                    mapping, Managing support and contact requests, Registration
                    and authentication, Content performance and features testing
                    (A/B testing), Handling payments and Interaction with
                    external social networks and platforms. The Personal Data
                    used for each purpose is outlined in the specific sections
                    of this document.
                  </p>
                  <h2>Further information about Personal Data</h2>
                  <p>
                    The Service Is Not Directed to Children Under the Age of 13
                    Users declare themselves to be adult according to their
                    applicable legislation. Minors may use this Application only
                    with the assistance of a parent or guardian. Under no
                    circumstance persons under the age of 13 may use this
                    Application.
                  </p>
                  <p>
                    Selling Goods and Services Online The Personal Data
                    collected is used to provide the User with services or to
                    sell goods, including payment and possible delivery. The
                    Personal Data collected to complete the payment may include
                    the credit card, the bank account used for the transfer, or
                    any other means of payment envisaged. The kind of Data
                    collected by this Application depends on the payment system
                    used.
                  </p>
                  <h2>
                    Additional Information About Data Collection and Processing
                  </h2>
                  <p>
                    Legal Action The User’s Personal Data may be used for legal
                    purposes by the Data Controller, in Court or in the stages
                    leading to possible legal action arising from improper use
                    of this Application or the related services. The User
                    declares to be aware that the Data Controller may be
                    required to reveal personal data upon request of public
                    authorities.
                  </p>
                  <p>
                    Additional Information About User’s Personal Data In
                    addition to the information contained in this privacy
                    policy, this Application may provide the User with
                    additional and contextual information concerning particular
                    services or the collection and processing of Personal Data
                    upon request.
                  </p>
                  <p>
                    System Logs and Maintenance For operation and maintenance
                    purposes, this Application and any third party services may
                    collect files that record interaction with this Application
                    (System logs) or use for this purpose other Personal Data
                    (such as IP Address).
                  </p>
                  <p>
                    Information Not Contained in This Policy More details
                    concerning the collection or processing of Personal Data may
                    be requested from the Data Controller at any time. Please
                    see the contact information at the beginning of this
                    document.
                  </p>
                  <p>
                    The Rights of Users Users have the right, at any time, to
                    know whether their Personal Data has been stored and can
                    consult the Data Controller to learn about their contents
                    and origin, to verify their accuracy or to ask for them to
                    be supplemented, cancelled, updated or corrected, or for
                    their transformation into anonymous format or to block any
                    data held in violation of the law, as well as to oppose
                    their treatment for any and all legitimate reasons. Requests
                    should be sent to the Data Controller at the contact
                    information set out above. This Application does not support
                    “Do Not Track” requests. To determine whether any of the
                    third party services it uses honor the “Do Not Track”
                    requests, please read their privacy policies.
                  </p>
                  <p>
                    Changes to This Privacy Policy The Data Controller reserves
                    the right to make changes to this privacy policy at any time
                    by giving notice to its Users on this page. It is strongly
                    recommended to check this page often, referring to the date
                    of the last modification listed at the bottom. If a User
                    objects to any of the changes to the Policy, the User must
                    cease using this Application and can request that the Data
                    Controller remove the Personal Data. Unless stated
                    otherwise, the then-current privacy policy applies to all
                    Personal Data the Data Controller has about Users.
                  </p>
                  <p>
                    Information About This Privacy Policy The Data Controller is
                    responsible for this privacy policy, prepared starting from
                    the modules provided by Tranquil Hosting and hosted on
                    Tranquil Hosting’s servers.
                  </p>

                  <h2>Definitions and Legal References</h2>
                  <p>
                    Personal Data (or Data) Any information regarding a natural
                    person, a legal person, an institution or an association,
                    which is, or can be, identified, even indirectly, by
                    reference to any other information, including a personal
                    identification number.
                  </p>
                  <p>
                    Usage Data Information collected automatically from this
                    Application (or third party services employed in this
                    Application), which can include: the IP addresses or domain
                    names of the computers utilized by the Users who use this
                    Application, the URI addresses (Uniform Resource
                    Identifier), the time of the request, the method utilized to
                    submit the request to the server, the size of the file
                    received in response, the numerical code indicating the
                    status of the server’s answer (successful outcome, error,
                    etc.), the country of origin, the features of the browser
                    and the operating system utilized by the User, the various
                    time details per visit (e.g., the time spent on each page
                    within the Application) and the details about the path
                    followed within the Application with special reference to
                    the sequence of pages visited, and other parameters about
                    the device operating system and/or the User’s IT
                    environment.
                  </p>
                  <p>
                    User The individual using this Application, which must
                    coincide with or be authorized by the Data Subject, to whom
                    the Personal Data refers.
                  </p>
                  <p>
                    Data Subject The legal or natural person to whom the
                    Personal Data refers.
                  </p>
                  <p>
                    Data Processor (or Data Supervisor) The natural person,
                    legal person, public administration or any other body,
                    association or organization authorized by the Data
                    Controller to process the Personal Data in compliance with
                    this privacy policy.
                  </p>
                  <p>
                    Data Controller (or Owner) The natural person, legal person,
                    public administration or any other body, association or
                    organization with the right, also jointly with another Data
                    Controller, to make decisions regarding the purposes, and
                    the methods of processing of Personal Data and the means
                    used, including the security measures concerning the
                    operation and use of this Application. The Data Controller,
                    unless otherwise specified, is the Owner of this
                    Application.
                  </p>
                  <p>
                    This Application The hardware or software tool by which the
                    Personal Data of the User is collected.
                  </p>
                  <p>
                    Cookies Small piece of data stored in the User’s device.
                  </p>
                  <p>
                    Legal Information Notice to European Users: this privacy
                    statement has been prepared in fulfillment of the
                    obligations under Art. 10 of EC Directive n. 95/46/EC, and
                    under the provisions of Directive 2002/58/EC, as revised by
                    Directive 2009/136/EC, on the subject of Cookies. This
                    privacy policy relates solely to this Application.
                  </p>
                </MarkdownContent>
              </Col>
              <Col lg={5} xl={4}>
                <StickyBox offsetTop={170}>
                  <div className="form-wrapper">
                    <h2 className="form-title">Request a Quote</h2>
                    <RequestForm location = { location } formId = { formId }  />
                  </div>
                </StickyBox>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default PrivacyPolicy
